<template>
  <div class="elv-report-reconciliation-detail-header">
    <el-tabs
      v-model="tabActiveName"
      type="border-card"
      class="elv-report-reconciliation-detail-tab"
      @tab-click="onChangeTab"
    >
      <el-tab-pane name="entity-reconciliation-data-sets" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('reconciliation.dataSets') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-details" class="elv-report-detail-pane">
        <template #label>
          <p :class="{ 'disabled-tab': disabledTouchTab }">
            {{ t('reconciliation.detailsReconciliation') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-automation" class="elv-report-detail-pane">
        <template #label>
          <p :class="{ 'disabled-tab': disabledTouchTab }">
            {{ t('reconciliation.automation') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-matches" class="elv-report-detail-pane">
        <template #label>
          <p :class="{ 'disabled-tab': disabledTouchTab }">
            {{ t('reconciliation.matches') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-report" class="elv-report-detail-pane">
        <template #label>
          <p :class="{ 'disabled-tab': disabledTouchTab }">
            {{ t('reconciliation.report') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-reconciliation-history" class="elv-report-detail-pane">
        <template #label>
          <p :class="{ 'disabled-tab': disabledTouchTab }">
            {{ t('reconciliation.history') }}
          </p>
        </template>
      </el-tab-pane>
    </el-tabs>

    <div class="elv-report-reconciliation-detail-header-right">
      <el-tooltip
        v-model:visible="isShowChangeDataSetChangeTip"
        placement="top"
        trigger="hover"
        popper-class="elv-reconciliation-matched-date-set-changed-tip-popover"
        :show-arrow="false"
        :disabled="!reconciliationTaskHasRefreshData"
        :offset="6"
      >
        <el-button
          class="elv-report-reconciliation-detail-header-right-refresh-btn"
          :class="{
            'disabled-tab':
              hasReconciliationDataSetUnchanged || ['MATCHING', 'DELETING'].includes(reconciliationTaskDetail.status)
          }"
          type="primary"
          @click="refreshTaskDataSource"
        >
          <img
            v-if="isLoadingReconciliationTask"
            class="loading-animation"
            src="@/assets/img/reports/sources-sync-loading.png"
            alt="loading"
          />

          <SvgIcon
            v-else
            name="update-now"
            :width="14"
            :height="14"
            :fill="
              hasReconciliationDataSetUnchanged || ['MATCHING', 'DELETING'].includes(reconciliationTaskDetail.status)
                ? '#D0D3D6'
                : '#838D95'
            "
          />
          {{ t('reconciliation.refreshData') }}
          <SvgIcon
            v-if="reconciliationTaskHasRefreshData"
            name="Information-Filled"
            :fill="'#636B75'"
            :width="12"
            :height="12"
            style="margin-top: 3px; margin-left: 4px"
          />
        </el-button>
        <template #content>
          <div>{{ t('reconciliation.taskDataSetChangeTipInfo') }}</div>
        </template>
      </el-tooltip>
      <el-button
        class="elv-report-reconciliation-detail-header-right-edit-task-btn"
        type="primary"
        @click="onOpenEditTaskDataDialog"
      >
        <SvgIcon name="sources-edit" :width="14" :height="14" :fill="'#838D95'" />
        {{ t('reconciliation.editTask') }}
      </el-button>
      <SvgIcon
        class="elv-report-reconciliation-detail-header-right-delete-task-btn"
        name="sources-delete"
        :width="14"
        :height="14"
        :fill="'#838D95'"
        @click="onOpenDeleteTaskDialog"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import { isEmpty } from 'lodash-es'

const emit = defineEmits(['onRefreshTaskDataSource', 'onOpenEditTaskDataDialog', 'onOpenDeleteTaskDialog'])

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const isShowChangeDataSetChangeTip = ref(false)
const reconciliationStore = useReconciliationStore()
const { reconciliationTaskDetail } = storeToRefs(reconciliationStore)

const tabActiveName = ref('entity-reconciliation-data-sets')

// 当前对账任务数据集为空，则禁用tab点击
const disabledTouchTab = computed(() => {
  return (
    !reconciliationStore.reconciliationTaskDataSetList?.sideAList?.length &&
    !reconciliationStore.reconciliationTaskDataSetList?.sideBList?.length &&
    isEmpty(reconciliationStore.reconciliationTaskDataSetList?.adjustmentJournal)
  )
})

const hasReconciliationDataSetUnchanged = computed(() => {
  return reconciliationTaskDetail.value?.dataStatus === 'UNCHANGED' && disabledTouchTab.value
})

const reconciliationTaskHasRefreshData = computed(() => {
  return reconciliationTaskDetail.value?.dataStatus === 'CHANGED'
})

const isLoadingReconciliationTask = computed(() => {
  return reconciliationTaskDetail.value.status === 'LOADING'
})

const onChangeTab = (tab: any) => {
  router.push({
    name: tab.props.name
  })
}

const refreshTaskDataSource = () => {
  if (reconciliationTaskDetail.value.status === 'NORMAL') {
    emit('onRefreshTaskDataSource')
  }
}

/**
 * 打开编辑任务数据对话框
 */
const onOpenEditTaskDataDialog = () => {
  emit('onOpenEditTaskDataDialog')
}

/**
 * 打开删除对账任务确认对话框
 */
const onOpenDeleteTaskDialog = () => {
  emit('onOpenDeleteTaskDialog')
}

watchEffect(() => {
  isShowChangeDataSetChangeTip.value = reconciliationTaskHasRefreshData.value
})

onMounted(() => {
  tabActiveName.value = String(route.name)
})

watch(
  () => route,
  () => {
    if (typeof route?.name === 'string' && route.name.indexOf('entity-reconciliation') !== -1) {
      tabActiveName.value = String(route.name)
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.elv-report-reconciliation-detail-header {
  position: relative;

  .elv-report-reconciliation-detail-tab {
    .el-tabs__item {
      &:has(.disabled-tab) {
        pointer-events: none;
        border: 1px solid #edf0f3;
        color: rgba(99, 107, 117, 0.3);
      }
    }
  }
}

.elv-report-reconciliation-detail-header-right {
  height: 34px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 0;

  button {
    background-color: transparent;
    border: none;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    color: #1e2024;
    width: auto;
    padding: 0px;
    margin-left: 14px;
    display: flex;
    align-items: center;

    &.disabled-tab {
      color: #d0d3d6;
      pointer-events: none;
    }

    &:hover {
      background-color: transparent;
      border: none;
      color: inherit;
    }

    svg {
      margin-right: 6px;
    }

    .loading-animation {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      animation: loading-rotate 2s linear infinite;
    }
  }

  &-delete-task-btn {
    margin-left: 14px;
    cursor: pointer;

    &:hover {
      fill: #1e2024;
    }
  }

  &-refresh-btn:not(.disabled-tab) {
    &:hover svg {
      fill: #1e2024;
    }
  }

  &-edit-task-btn {
    &:hover svg {
      fill: #1e2024;
    }
  }
}

.elv-reconciliation-matched-date-set-changed-tip-popover {
  width: 267px;
  padding: 8px 12px;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 16px;
}
</style>
