<template>
  <div class="elv-reconciliation-match-header">
    <div class="elv-reconciliation-match-header__title" @click="onJumpPage">
      <SvgIcon name="arrow-right-line" width="18" height="18" />
      <p>{{ t('reconciliation.backToTaskList') }}</p>
    </div>
    <div class="elv-reconciliation-match-header__task-info">
      <span class="elv-reconciliation-match-header__task-info-name">{{
        reconciliationStore?.reconciliationTaskDetail?.name
      }}</span>
      <span class="elv-reconciliation-match-header__task-info-date"
        >{{ t('reconciliation.dataRange') }}:
        <DateTimeFromToCell
          :fromTime="reconciliationStore?.reconciliationTaskDetail?.fromDatetime"
          :endTime="reconciliationStore?.reconciliationTaskDetail?.toDatetime"
      /></span>
    </div>
  </div>
  <HeaderTabs
    @onOpenEditTaskDataDialog="showTaskDialog = true"
    @onOpenDeleteTaskDialog="showDeleteTaskDialog = true"
    @onRefreshTaskDataSource="showRefreshDialog = true"
  />
  <router-view></router-view>
  <TaskDialog
    v-if="showTaskDialog"
    v-model:show="showTaskDialog"
    model="edit"
    :currentData="currentTaskData"
    @onResetList="initReconciliationTaskDetailInfo"
  />
  <ReconciliationTaskStatusChangeDialog
    v-if="showDeleteTaskDialog"
    v-model:show="showDeleteTaskDialog"
    v-model:agree="agreeRemovedData"
    v-model:loading="deleteTaskLoading"
    :title="t('reconciliation.deleteReconciliationTask')"
    :confirmText="t('button.delete')"
    :cancelText="t('button.cancel')"
    @onConfirmChangeStatus="onDeleteReconciliationTask"
    @onCloseDialog="showDeleteTaskDialog = false"
  >
  </ReconciliationTaskStatusChangeDialog>
  <ReconciliationTaskStatusChangeDialog
    v-if="showRefreshDialog"
    v-model:show="showRefreshDialog"
    v-model:agree="agreeRemovedData"
    v-model:loading="refreshDataSetsLoading"
    :title="t('reconciliation.refreshData')"
    :confirmText="t('button.confirm')"
    :cancelText="t('button.cancel')"
    @onConfirmChangeStatus="onRefreshTaskDataSource"
    @onCloseDialog="showRefreshDialog = false"
  >
    <template #customContent>
      <p>{{ t('reconciliation.refreshDataWaringInfo') }}</p>
    </template>
  </ReconciliationTaskStatusChangeDialog>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import HeaderTabs from './components/HeaderTabs.vue'
import TaskDialog from './components/TaskDialog.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import DateTimeFromToCell from '../components/DateTimeFromToCell.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import ReconciliationTaskStatusChangeDialog from './components/ReconciliationTaskStatusChangeDialog.vue'
import { ReconciliationTaskDataSetItemDetailType, ReconciliationTaskDetailType } from '#/ReconciliationTypes'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reconciliationStore = useReconciliationStore()

const showTaskDialog = ref(false)
const agreeRemovedData = ref(false)
const showRefreshDialog = ref(false)
const deleteTaskLoading = ref(false)
const showDeleteTaskDialog = ref(false)
const refreshDataSetsLoading = ref(false)

const entityId = computed(() => {
  return route.params?.entityId as string
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const currentTaskData = computed(() => {
  return reconciliationStore.reconciliationTaskDetail
})

const onJumpPage = () => {
  router.push({
    name: 'entity-reconciliation-tasks'
  })
}

/**
 * @description: 初始化对账任务详情数据
 */
const initReconciliationTaskDetailInfo = async () => {
  try {
    reconciliationStore.detailLoading = true
    await Promise.allSettled([
      reconciliationStore.fetchReconciliationTaskDetail(entityId.value, reconciliationTaskId.value),
      reconciliationStore.fetchReconciliationDataSetList(entityId.value, reconciliationTaskId.value)
    ])
  } catch (error) {
    console.log(error)
  } finally {
    reconciliationStore.detailLoading = false
  }
}

/**
 * 删除对账任务
 */
const onDeleteReconciliationTask = async () => {
  try {
    deleteTaskLoading.value = true
    await ReconciliationApi.deleteReconciliationTask(entityId.value, reconciliationTaskId.value, {
      isRemoveReconciliationAdjustment: agreeRemovedData.value
    })
    ElMessage.warning(t('report.deleting'))
    showDeleteTaskDialog.value = false
    onJumpPage()
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    deleteTaskLoading.value = false
  }
}

/**
 * 刷新任务数据源
 */
const onRefreshTaskDataSource = async () => {
  try {
    refreshDataSetsLoading.value = true
    await ReconciliationApi.loadReconciliationTaskData(entityId.value, reconciliationTaskId.value, {
      isRemoveReconciliationAdjustment: agreeRemovedData.value
    })
    initReconciliationTaskDetailInfo()
    showRefreshDialog.value = false
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    agreeRemovedData.value = false
    refreshDataSetsLoading.value = false
  }
}

watch(
  () => route,
  async () => {
    const valuationRoutes = [
      'entity-reconciliation-data-sets',
      'entity-reconciliation-details',
      'entity-reconciliation-automation',
      'entity-reconciliation-matches',
      'entity-reconciliation-report',
      'entity-reconciliation-history'
    ]
    if (valuationRoutes.includes(String(route.name))) {
      initReconciliationTaskDetailInfo()
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  reconciliationStore.reconciliationTaskBalanceList = {
    currencyList: [],
    list: []
  }
  reconciliationStore.reconciliationTaskDataSetList = {
    sideAList: [],
    sideBList: [],
    adjustmentJournal: {} as ReconciliationTaskDataSetItemDetailType
  }
  reconciliationStore.reconciliationTaskDetail = {} as ReconciliationTaskDetailType
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-match-header {
  box-sizing: border-box;
  width: 100%;
  padding-left: 23px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}

.elv-reconciliation-match-header__title {
  display: flex;
  align-items: center;

  svg {
    transform: rotate(180deg);
    fill: #838d95;
    transition: fill 0.1s;
  }

  p {
    margin-left: 8px;
    padding-left: 8px;
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 18px;
      background-color: #edf0f3;
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: #1e2024;
    }
  }
}

.elv-reconciliation-match-header__task-info {
  display: flex;
  flex-direction: column;
  font-family: 'Plus Jakarta Sans';
  margin-top: 16px;

  &-name {
    font-size: 16px;
    font-weight: 600;
    color: #0e0f11;
    line-height: 20px;
    margin-bottom: 4px;
    height: 20px;
  }

  &-date {
    font-size: 13px;
    font-weight: 400;
    color: #838d95;
    display: flex;
    align-items: center;

    :deep(.elv-cell-date-time-main-price) {
      .elv-cell-date-time-main-top {
        font-family: 'Barlow';
        color: #1e2024;
        line-height: 20px;
      }
    }
  }
}
</style>
